const isInIframe = () => window.location !== window.parent.location;

const alertIfThirdPartyCookiesDisabled = () => {
  document.hasStorageAccess().then((hasAccess) => {
    if (hasAccess) return;

    const cookiesMessageEl = document.getElementById('enable-cookies-message');
    if (cookiesMessageEl) cookiesMessageEl.classList.remove('hidden');
  });
};

if (isInIframe()) alertIfThirdPartyCookiesDisabled();
